import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/article-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`AZGFD issues 25,136 tags for 2022 pronghorn and elk hunts`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "670px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/36d10dfdcb6801cf366e2a8810aa735e/63a7e/pronghorn.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFMe2k4phf/xAAZEAACAwEAAAAAAAAAAAAAAAAAEQIDIRD/2gAIAQEAAQUC6q4mPT//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEhP/2gAIAQMBAT8Bhm//xAAVEQEBAAAAAAAAAAAAAAAAAAAAE//aAAgBAgEBPwGir//EABoQAAICAwAAAAAAAAAAAAAAAAABAiEQQYH/2gAIAQEABj8CzUG+lQRo/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAERgRAhUf/aAAgBAQABPyGVlOWDEbLAnwtH/9oADAMBAAIAAwAAABDDP//EABcRAQADAAAAAAAAAAAAAAAAAAABUXH/2gAIAQMBAT8Qi7T/xAAYEQACAwAAAAAAAAAAAAAAAAAAARFBUf/aAAgBAgEBPxBrRLD/xAAaEAEBAQEAAwAAAAAAAAAAAAABEQAhMWFx/9oACAEBAAE/EBuDdSRx8ddMHsZdN9aqumYC+DP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "pronghorn",
            "title": "pronghorn",
            "src": "/static/36d10dfdcb6801cf366e2a8810aa735e/63a7e/pronghorn.jpg",
            "srcSet": ["/static/36d10dfdcb6801cf366e2a8810aa735e/e07e9/pronghorn.jpg 200w", "/static/36d10dfdcb6801cf366e2a8810aa735e/066f9/pronghorn.jpg 400w", "/static/36d10dfdcb6801cf366e2a8810aa735e/63a7e/pronghorn.jpg 670w"],
            "sizes": "(max-width: 670px) 100vw, 670px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The Arizona Game and Fish Department (AZGFD) has released the draw results for 2022 pronghorn and elk hunts.`}</p>
    <p>{`Customers must have an AZGFD portal account to view draw results and bonus points. Draw results no longer are made available through an automated phone system.`}</p>
    <p>{`A free AZGFD portal account (dependent account features are available) can be created by visiting accounts.azgfd.com/Account/Register. A portal account allows customers to create a secure account where they can view and manage their contact information, as well as their licenses, draw results history and bonus points in their personal “My AZGFD Dashboard” section.`}</p>
    <p>{`For questions about creating a portal account, call the department at 602-942-3000 and press “7.”`}</p>
    <p>{`By the numbers:`}</p>
    <ul>
      <li parentName="ul">{`25,136: The total number of hunt permit-tags issued.`}</li>
      <li parentName="ul">{`217,839: The total number of those who applied for hunts or bonus points.`}</li>
      <li parentName="ul">{`176,822: The total number of applications submitted.`}</li>
    </ul>
    <p>{`All hunt permit-tags were expected to be mailed by April 1.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      